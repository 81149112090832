// This file was automatically generated from concierge.venue.perkspopup.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Venue == 'undefined') { Nightloop.Templates.Concierge.Venue = {}; }


Nightloop.Templates.Concierge.Venue.ViewPerksPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'concierge-view-venue-perks-popup', header_html: 'Perks for ' + soy.$$escapeHtml(opt_data.content.date_display) + ' at ' + soy.$$escapeHtml(opt_data.content.venue.name), show_close_link: 'true', DynamicTemplate: 'Nightloop.Templates.Concierge.Venue.ViewPerksPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Venue.ViewPerksPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="white-box-block">The following perks are available and may be selected at checkout:</div><ul>');
  var perkList1531 = opt_data.content.perks_list;
  var perkListLen1531 = perkList1531.length;
  for (var perkIndex1531 = 0; perkIndex1531 < perkListLen1531; perkIndex1531++) {
    var perkData1531 = perkList1531[perkIndex1531];
    output.append('<li><div class="bold">', soy.$$escapeHtml(perkData1531.title), '</div><div>', perkData1531.description_html, '</div>', (perkData1531.url) ? '<div><a href="' + soy.$$escapeHtml(perkData1531.url) + '" target="_blank">more information <img src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/open-new-window.gif" /></a></div>' : '', '</li>');
  }
  output.append('</ul><script type="text/javascript">$(document).ready( function() {});<\/script>');
  return opt_sb ? '' : output.toString();
};
