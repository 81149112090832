// This file was automatically generated from concierge.reservation.custom.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Reservation == 'undefined') { Nightloop.Templates.Concierge.Reservation = {}; }


Nightloop.Templates.Concierge.Reservation.CustomPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'custom-reservation-popup', header_html: 'Reservation Request', show_close_link: 'true', DynamicTemplate: 'Nightloop.Templates.Concierge.Reservation.CustomPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Reservation.CustomPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data.is_complete) {
    output.append('<div id="custom-reservation-success-message" class="gold-message">Thank you. Your request has been submitted.</div><div class="body-block"><p><a class="close-main-colorbox-link" href="javascript:void(0);">Click here to close</a></p></div>');
  } else {
    output.append('<form id="custom-reservation-popup-form" method="POST" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/res/custom"><div class="body-block"><p>Hello ', soy.$$escapeHtml(opt_data.request.user.first_name), ',</p><div class="venue-selected-insns no-display"><p>Please provide us with some reservation details and we are happy to contact this venue on your behalf.</p></div><div class="no-venue-selected-insns no-display"><p>While we expand our venue community available for online booking, we\'re at your service for all your client\'s reservation needs at any venue in any city at any time. Didn\'t find a specific venue or need ideas for where to send clients?  No problem - just provide us with some details and we\'ll get back to you within 30 minutes.</p></div><div><div class="table-row"><div class="row-header float-left">First Name:</div><div class="row-content float-left"><input class="input-fname" type="text" name="first_name" ', (opt_data.content.first_name) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.first_name) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Last Name:</div><div class="row-content float-left"><input class="input-lname" type="text" name="last_name" ', (opt_data.content.last_name) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.last_name) + '\'' : '', '/></div><div class="float-end"></div><div class="table-row"><div class="row-header float-left">Phone Number*:</div><div class="row-content float-left"><input class="input-phone ');
    var errList960 = opt_data.err_types;
    var errListLen960 = errList960.length;
    for (var errIndex960 = 0; errIndex960 < errListLen960; errIndex960++) {
      var errData960 = errList960[errIndex960];
      output.append((errData960 == 'phone_number') ? 'error-outline' : '');
    }
    output.append('" type="text" name="phone_number" value=\'', (opt_data.content.phone_number) ? soy.$$escapeHtml(opt_data.content.phone_number) : '', '\'/></div><div class="float-end"></div></div></div><div class="table-row"><div class="row-header float-left">Venue(s):</div><div class="row-content float-left"><input class="input-venue" type="text" name="venue" ', (opt_data.content.venue) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.venue) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Date*:</div><div class="row-content float-left"><input class="customres-datepicker input-date datepicker ');
    var errList976 = opt_data.err_types;
    var errListLen976 = errList976.length;
    for (var errIndex976 = 0; errIndex976 < errListLen976; errIndex976++) {
      var errData976 = errList976[errIndex976];
      output.append((errData976 == 'date') ? 'error-outline' : '');
    }
    output.append('" type="text" name="date-localized" ', (opt_data.content.date) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.date) + '\'' : '', '/><input class="customres-datepicker-submit" type="hidden" name="date" ', (opt_data.content.date) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.date) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Preferred Time*:</div><div class="row-content float-left"><input placeholder="7pm-8:30pm" class="customres-timepicker ');
    var errList994 = opt_data.err_types;
    var errListLen994 = errList994.length;
    for (var errIndex994 = 0; errIndex994 < errListLen994; errIndex994++) {
      var errData994 = errList994[errIndex994];
      output.append((errData994 == 'time') ? 'error-outline' : '');
    }
    output.append('" name="time" type="text" ', (opt_data.content.time) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.time) + '\'' : '', '/></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Party Size*:</div><div class="row-content float-left"><input class="input-partysize ');
    var errList1006 = opt_data.err_types;
    var errListLen1006 = errList1006.length;
    for (var errIndex1006 = 0; errIndex1006 < errListLen1006; errIndex1006++) {
      var errData1006 = errList1006[errIndex1006];
      output.append((errData1006 == 'partysize') ? 'error-outline' : '');
    }
    output.append('" type="text" name="partysize" ', (opt_data.content.partysize) ? ' value=\'' + soy.$$escapeHtml(opt_data.content.partysize) + '\'' : '', '/>&nbsp;<div id="popup-gender-ratio">(<label><select class="number "  name="mf_ratio_male" id="select-mf-ratio-male"><option class="number" value="" selected=\'selected\'></option>');
    for (var i1018 = 1; i1018 < 51; i1018++) {
      output.append((opt_data.content.mf_ratio_male) ? '<option class="number" value="' + soy.$$escapeHtml(i1018) + '" ' + ((opt_data.content.mf_ratio_male == i1018) ? 'selected="selected"' : '') + '>' + soy.$$escapeHtml(i1018) + '</option>' : '<option class="number" value="' + soy.$$escapeHtml(i1018) + '" >' + soy.$$escapeHtml(i1018) + '</option>');
    }
    output.append('</select> M&nbsp;</label><label><select class="number " name="mf_ratio_female" id="select-mf-ratio-female"><option class="number" value="" selected=\'selected\'></option>');
    for (var i1037 = 1; i1037 < 51; i1037++) {
      output.append((opt_data.content.mf_ratio_female) ? '<option class="number" value="' + soy.$$escapeHtml(i1037) + '" ' + ((opt_data.content.mf_ratio_female == i1037) ? 'selected="selected"' : '') + '>' + soy.$$escapeHtml(i1037) + '</option>' : '<option class="number" value="' + soy.$$escapeHtml(i1037) + '" >' + soy.$$escapeHtml(i1037) + '</option>');
    }
    output.append('</select> F</label>)</div></div><div class="float-end"></div></div><div class="table-row"><div class="row-header float-left">Request Details:</div><div class="row-content float-left"><div class="input-container request-details-container"><div class="input-prompt request-details-prompt">type any details about the party and any special requests that make this reservation unique</div><textarea class="custom-reservation-textarea ');
    var errList1056 = opt_data.err_types;
    var errListLen1056 = errList1056.length;
    for (var errIndex1056 = 0; errIndex1056 < errListLen1056; errIndex1056++) {
      var errData1056 = errList1056[errIndex1056];
      output.append((errData1056 == 'custom_request') ? 'error-outline' : '');
    }
    output.append(' request-details" name="custom_request" maxlength="500">', (opt_data.content.custom_request) ? soy.$$escapeHtml(opt_data.content.custom_request) : '', '</textarea></div></div><div class="float-end"></div></div></div></div><div class="body-block edit-links"><div class="required-text float-left italics">*required</div><div class="float-right"><div class="link-next-to-button-div float-left"><a class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> <a class="float-left" id="custom-reservation-submit-btn" href="javascript:void(0);">');
    Nightloop.Templates.Widget.GoldButton({text: 'SUBMIT REQUEST', size: 'medium'}, output);
    output.append('</a></div><div class="float-end"></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Concierge.Reservation.Custom.bindClickHandlers();});<\/script>');
  }
  return opt_sb ? '' : output.toString();
};
